<template>
  <div id="simple-page" class="cover" data-image-src="assets/img/bg/page.jpg">
    <div class="container">

      <!-- #e404 -->
      <div id="e404">
        <img src="assets/img/bg/404.png" alt="Chyba 404">
        <router-link to="/" class="bttn -orange -small" v-translate>späť na úvod</router-link>
      </div>
      <!-- /#e404 -->

    </div>
  </div>
</template>

<script>
    export default {
        name: 'contact',
        metaInfo() {
            return {
                title: this.$gettext('404_title'),
                titleTemplate: '%s | Webpartner',
                htmlAttrs: {
                    lang: 'sk-SK',
                    amp: undefined
                },
                meta: [
                    {name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
                ],
            }
        },

    }
</script>